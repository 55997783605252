import React, {useState, useEffect } from 'react';
import { Typography, Box, Grid, List, ListItem, ListItemIcon, Link } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import QuestionForm from './QuestionForm';
import { DefaultApi, Configuration } from 'quepasa-ai';

const rag_token = "saas_arxiv_rag_docs:nPB13LkrDO5NBZiZk8pb4CxyMp5jn/hvdohQL+hy1pPszdQg1I1JDvVGhW+3vss/";

const questions: string[] = [
	"What is RAG?",
	"What do you know about multimodal RAG?",
	"Show papers about embeddings",
	"Pros and cons of using rerankers",
	"Show papers about graph RAG"
];

function FinBench(): JSX.Element {
	const [quepasa, setQuePasa] = useState<DefaultApi | null>(null);
	const [randomQuestions, setRandomQuestions] = useState<string[]>([]);
    const [closeDomains, setCloseDomains] = useState(false);

	const handleInteractionWithQuestionForm = () => {
		setCloseDomains(!closeDomains);
	};

	useEffect(() => {
		const apiInstance = new DefaultApi(new Configuration({ accessToken: rag_token }));
		setQuePasa(apiInstance);

		const getRandomQuestions = () => {
            const shuffled = questions.sort(() => 0.5 - Math.random());
            return shuffled.slice(0, 4);
        };
        setRandomQuestions(getRandomQuestions());

	}, []);

	return (
		<>
			<div className="adaptive-flex"  style={{marginTop: "30px"}}>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
					<a href="https://quepasa.ai" target="_blank">
		          		<img src="/logo.png" alt="Logo" style={{ width: '150px', height: 'auto' }} />
					</a>
		        </Box>

				<Typography variant="h5" component="h2">
					RAG Papers Search
				</Typography>
			</div>

			<Typography variant="body1" >
				Hello! I'm the QuePasa.ai bot, here to help you search for research papers on Retrieval-Augmented Generation (RAG)
				from&nbsp;<a href="https://www.arxiv.org/" target="_blank">arxiv.org</a>.
				Just tell me what you're looking for, and I'll find relevant papers for you!
			</Typography>

			<div style={{ marginTop: '20px', marginBottom: '20px' }}>
				{quepasa && <QuestionForm domains={Object.keys([""])} questions={randomQuestions} url={true} onInteraction={handleInteractionWithQuestionForm} quepasa={quepasa} />}
			</div>
		</>
	);
}

export default FinBench;
