import React, {useState, useEffect } from 'react';
import { Typography, Box, Grid, List, ListItem, ListItemIcon, Link } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import QuestionForm from './QuestionForm';
import { DefaultApi, Configuration } from 'quepasa-ai';

const fb_token = "saas_finance_bench_pymupdf_domain:u4qlQ0DhojxMdB0WVAhf846DTLVso5Z0M4k/uT1+ZLhbc21fqHgdZWDjbClC5Zmg";
const description = "FinanceBench is a dataset and benchmarking suite designed to evaluate the performance of NLP and RAG systems on financial documents.";
const description2 = "FinanceBench includes financial reports covering the past few years for 40 companies, such as:"
const fb_link = "https://arxiv.org/pdf/2311.11944"
const companies: string[] = [ "3M", "Activision Blizzard", "Adobe", "Amazon", "Amcor", "AMD", "American Express", "Apple", "Boeing", "Coca-Cola", "Corning",
							"Ebay", "Fedex", "Foot Locker", "General Mills", "Intel", "Johnson & Johnson", "Lockheed Martin", "McDonald's", "MGM", "Microsoft", "Netflix",
							"Nike", "PepsiCo", "Pfizer", "Walmart", "Ulta Beauty", "Verizon" ];

const questions: string[] = [
	"What is the FY2018 capital expenditure amount (in USD millions) for 3M? Give a response to the question by relying on the details shown in the cash flow statement.",
	"Assume that you are a public equities analyst. Answer the following question by primarily using information that is shown in the balance sheet: what is the year end FY2018 net PPNE for 3M? Answer in USD billions.",
	"Which debt securities are registered to trade on a national securities exchange under 3M's name as of Q2 of 2023?",
	"Does 3M maintain a stable trend of dividend distribution?",
	"What industry does AMCOR primarily operate in?",
	"What is the nature & purpose of AMCOR's restructuring liability as oF Q2 of FY2023 close?",
	"What is Amcor's year end FY2020 net AR (in USD millions)? Address the question by adopting the perspective of a financial analyst who can only use the details shown within the balance sheet.",
	"What Was AMCOR's Adjusted Non GAAP EBITDA for FY 2023",
	"What are the major products and services that AMD sells as of FY22?",
	"Among operations, investing, and financing activities, which brought in the most (or lost the least) cash flow for AMD in FY22?",
	//"What are the geographies that American Express primarily operates in as of 2022?",
	"What was the largest liability in American Express's Balance Sheet in 2022?",
	//"Was American Express able to retain card members during 2022?",
	"How much (in USD billions) did American Water Works pay out in cash dividends for FY2020? Compute or extract the answer by primarily using the details outlined in the statement of cash flows.",
	"Who are the primary customers of Boeing as of FY2022?",
	"What production rate changes is Boeing forecasting for FY2023?",
	"We need to calculate a financial metric by using information only provided within the balance sheet. Please answer the following question: what is Boeing's year end FY2018 net property, plant, and equipment (in USD millions)?",
	"What is Coca Cola's FY2021 COGS % margin? Calculate what was asked by utilizing the line items clearly shown in the income statement.",
	"Taking into account the information outlined in the income statement, what is the FY2019 - FY2021 3 year average unadjusted operating income % margin for Corning? Answer in units of percents and round to one decimal place.",
	"Does Foot Locker's new CEO have previous CEO experience in a similar company to Footlocker?",
	"According to the information provided in the statement of cash flows, what is the finance year 2020 free cash flow (FCF) for General Mills? FCF here is defined as: (cash from operations - capex). Answer in USD millions.",
	"Are JnJ's FY2022 financials that of a high growth company?",
	"What is the amount of the gain accruing to JnJ as a result of the separation of its Consumer Health business segment, as of August 30, 2023?",
	"What is the amount of the cash proceeds that JnJ realised from the separation of Kenvue (formerly Consumer Health business segment), as of August 30, 2023?",
	"How did JnJ's US sales growth compare to international sales growth in FY2022?",
	"Which business segment of JnJ will be treated as a discontinued operation from August 30, 2023 onward?",
	"What is Lockheed Martin's FY2021 net working capital? Define net working capital as total current assets less total current liabilities. Answer in USD millions. Respond to the question by assuming the perspective of an investment analyst who can only use the details shown within the balance sheet.",
	"Which region had the Highest EBITDAR Contribution for MGM during FY2022?",
	"According to the details clearly outlined within the balance sheet, how much total current assets did Nike have at the end of FY2019? Answer in USD millions.",
	"What are the geographies that Pepsico primarily operates in as of FY2022?",
	"At the Pepsico AGM held on May 3, 2023, what was the outcome of the shareholder vote on the shareholder proposal for a congruency report by Pepsico on net-zero emissions policies?",
	"By how much did Pepsico increase its unsecured five year revolving credit agreement on May 26, 2023?",
	"As of May 26, 2023, what is the total amount Pepsico may borrow under its unsecured revolving credit agreements?",
	"As of FY2023Q1, by how many percentage points did Pepsico raise full year guidance in respect of core constant currency EPS growth?",
	"Has Pepsico reported any materially important ongoing legal battles from FY2022 and FY2021?",
	"What percent of Ulta Beauty's total spend on stock repurchases for FY 2023 occurred in Q4 of FY2023?",
	"Among all of the derivative instruments that Verizon used to manage the exposure to fluctuations of foreign currencies exchange rates or interest rates, which one had the highest notional value in FY 2021?"
];

function FinBench(): JSX.Element {
	const [quepasa, setQuePasa] = useState<DefaultApi | null>(null);
	const [randomQuestions, setRandomQuestions] = useState<string[]>([]);
    const [closeDomains, setCloseDomains] = useState(false);

	const [showAllCompanies, setShowAllCompanies] = useState(false);
	const toggleShowAll = () => setShowAllCompanies(!showAllCompanies);
	const visibleCompanies = showAllCompanies ? companies : companies.slice(0, 9);

	const handleInteractionWithQuestionForm = () => {
		setCloseDomains(!closeDomains);
	};

	useEffect(() => {
		const apiInstance = new DefaultApi(new Configuration({ accessToken: fb_token }));
		setQuePasa(apiInstance);

		const getRandomQuestions = () => {
            const shuffled = questions.sort(() => 0.5 - Math.random());
            return shuffled.slice(0, 4);
        };
        setRandomQuestions(getRandomQuestions());

	}, []);

	const columnHeight = Math.ceil(visibleCompanies.length / 3);
	const companiesColumns = [
		visibleCompanies.slice(0, columnHeight),
		visibleCompanies.slice(columnHeight, columnHeight * 2),
		visibleCompanies.slice(columnHeight * 2)
	];

	return (
		<>
			<div className="adaptive-flex"  style={{marginTop: "30px"}}>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
					<a href="https://quepasa.ai" target="_blank">
		          		<img src="/logo.png" alt="Logo" style={{ width: '150px', height: 'auto' }} />
					</a>
		        </Box>

				<Typography variant="h5" component="h2">
					FINANCE BENCH
				</Typography>
			</div>

			<Typography variant="body1" >
				{description}
				&nbsp;You can read more about this benchmark&nbsp;
				<a href={fb_link} target="_blank">
					here
				</a>.
			</Typography>

			<Typography variant="body1" >
				{description2}
			</Typography>

			<Grid container spacing={2}>
				{companiesColumns.map((column, index) => (
					<Grid item xs={12} md={4} key={index}>
						<List>
							{column.map(company => (
								<ListItem key={company} sx={{ padding: 0 }}>
								  <ListItemIcon sx={{ minWidth: 'auto', marginRight: 1 }}>
									<CircleIcon fontSize="small" sx={{ fontSize: 8, color: 'text.secondary' }} />
								  </ListItemIcon>
								  <Typography variant="body1">{company}</Typography>
								</ListItem>
							))}
						</List>
					 </Grid>
				))}
			</Grid>

		   <Box textAlign="center">
			   <Link href="#" onClick={(e) => { e.preventDefault(); toggleShowAll(); }}>
				   {showAllCompanies ? 'Hide' : 'Show more companies'}
			   </Link>
		   </Box>

			<div style={{ marginTop: '20px', marginBottom: '20px' }}>
				{quepasa && <QuestionForm domains={Object.keys([""])} questions={randomQuestions} url={true} onInteraction={handleInteractionWithQuestionForm} quepasa={quepasa} />}
			</div>
		</>
	);
}

export default FinBench;
