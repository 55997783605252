import React, {useState, useEffect } from 'react';
import { Typography, Box, Grid, List, ListItem, ListItemIcon, Link } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import QuestionForm from './QuestionForm';
import LiveWiki from './LiveWiki';
import { DefaultApi, Configuration } from 'quepasa-ai';

import ReactMarkdown from 'react-markdown';

const ms_token = "saas_moonsite:waPWiR3zCQewnot/ylvqMSgXmxRzdVZT/ntzEObCfWIkzBBFrSPHNLsxoGX8k1iY";
const description: string[] = [
	"Testimonies transcribed into a consolidated document, Moonsite",
	"עדויות שתמלולן הסתיים מסמך מאוחד מונסייט"
];

const questions: string[] = [
	"Tell me about survival strategies of Jews of Sephardic origin",
	"Tell me about Jewish life before and during the early stages of World War II",
	"What can you tell about Holocaust survivors of Greek or Sephardic origin?",
	"Who is Rabbi Kortz?"
];

function MoonSite(): JSX.Element {
	const [quepasa, setQuePasa] = useState<DefaultApi | null>(null);
	const [randomQuestions, setRandomQuestions] = useState<string[]>([]);
    const [closeDomains, setCloseDomains] = useState(false);

	const handleInteractionWithQuestionForm = () => {
		setCloseDomains(!closeDomains);
	};

	useEffect(() => {
		const apiInstance = new DefaultApi(new Configuration({
			accessToken: ms_token,
		}));
		setQuePasa(apiInstance);

		const getRandomQuestions = () => {
            const shuffled = questions.sort(() => 0.5 - Math.random());
            return shuffled.slice(0, 4);
        };
        setRandomQuestions(getRandomQuestions());

	}, []);

	return (
		<>
			<div className="adaptive-flex"  style={{marginTop: "30px"}}>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
					<a href="https://quepasa.ai" target="_blank">
		          		<img src="/logo.png" alt="Logo" style={{ width: '150px', height: 'auto' }} />
					</a>
		        </Box>

				<Typography variant="h5" component="h2">
					Moonsite Testimonies
				</Typography>
			</div>

			{description.length > 0 && description.map((line, index) => (
				<ReactMarkdown
					key={index}
					components={{
						a: ({ node, ...props }) => (
						  <a {...props} target="_blank" rel="noopener noreferrer">
							{props.children}
						  </a>
						),
					}}
				>
					{line}
				</ReactMarkdown>
			))}

			<div style={{ marginBottom: '20px' }}>
				{quepasa && <LiveWiki quepasa={quepasa} />}
			</div>

			<div style={{ marginTop: '20px', marginBottom: '20px' }}>
				{quepasa && <QuestionForm domains={Object.keys([""])} questions={randomQuestions} url={false} onInteraction={handleInteractionWithQuestionForm} quepasa={quepasa} />}
			</div>
		</>
	);
}

export default MoonSite;
