import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ReactMarkdown from 'react-markdown';

interface LinkData {
  [key: string]: { title?: string, text?: string };
}

interface QuepasaMarkdownProps {
  answer: string;
  links?: LinkData;
  onEntityClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

const MyTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
	width: "500px",
    fontSize: 11,
	margin: 0
  },
}));

const QuepasaMarkdown: React.FC<QuepasaMarkdownProps> = ({ answer, links, onEntityClick }) => {
	const processAnswer = (text: string) => {
		if (links) {
			answer = answer.replace(/\[(\d+)\]/g, (match, p1) => {
				const index = parseInt(p1, 10);
				if (links && links[index]) {
					const link = links[index];
					return `**[${match}](?tooltip=${index})**`;
				} else {
					return match;
				}
		  	});

		  	const grouped = Object.entries(links).reduce((acc, [id, value]) => {
				const { title } = value;
				if (title) {
					if (!acc[title]) acc[title] = [];
					acc[title].push(id);
				}
				return acc;
		  	}, {} as Record<string, string[]>);

			const result = Object.entries(grouped)
			.map(([title, ids]) => `**[${ids.join("], [")}] - ${title}**`)
			.join("\n\n");

			answer += "\n\n" + result + "";
		}

		return answer;
	};

	const markdown = processAnswer(answer);

	return (
    	<div>
			<ReactMarkdown
				components={{
					a: ({ href, children, ...props }) => {
						if (href && href.startsWith("?entity=")) {
							return (
								<a
									{...props}
									href={href}
									onClick={onEntityClick}
									>
									{children}
								</a>
							);
						} else if (href && href.startsWith("?tooltip=")) {
							const id = href?.split('=')[1];
							if (id && links?.[id]) {
				      			return (
				        			<MyTooltip
										title={
											<div style={{ maxWidth: '600px', fontSize: '0.8rem' }}>
												<ReactMarkdown
													components={{
														h1: ({ children }) => <h1 style={{ fontSize: '1rem', margin: 0, textAlign: 'center' }}>{children}</h1>
													}}
												>
											  		{links[id].text || ''}
												</ReactMarkdown>
											</div>
										}
										arrow
									>
				          				<span
											style={{ cursor: 'pointer' }}
											onClick={(e: React.MouseEvent<HTMLAnchorElement>)=>{e.preventDefault();}}
										>
											{children}
										</span>
				        			</MyTooltip>
				      			);
							}
				    	}
						return <a {...props} href={href}>{children}</a>; // Default behavior for other links
					},
				}}
			>
				{markdown}
			</ReactMarkdown>
    	</div>
	);
};

export default QuepasaMarkdown;
