import React, {useState, useEffect } from 'react';
import { Typography, Box, Grid, List, ListItem, ListItemIcon, Link } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import QuestionForm from './QuestionForm';
import LiveWiki from './LiveWiki';
import { DefaultApi, Configuration } from 'quepasa-ai';

import ReactMarkdown from 'react-markdown';

const aic_token = "saas_anylogic_com_cases:3a9fCM98auryYD/yvi9bCN2yHqXyLOk3MalCTHo0ime/zFa+Jg2vM5QJnWhlb3xB";
const description: string[] = [
];

const questions: string[] = [
];

function Anylogic(): JSX.Element {
	const [quepasa, setQuePasa] = useState<DefaultApi | null>(null);
	const [randomQuestions, setRandomQuestions] = useState<string[]>([]);
    const [closeDomains, setCloseDomains] = useState(false);

	const handleInteractionWithQuestionForm = () => {
		setCloseDomains(!closeDomains);
	};

	useEffect(() => {
		const apiInstance = new DefaultApi(new Configuration({
			accessToken: aic_token,
			basePath: "https://6867-5-29-13-220.ngrok-free.app"
		}));
		setQuePasa(apiInstance);

		const getRandomQuestions = () => {
            const shuffled = questions.sort(() => 0.5 - Math.random());
            return shuffled.slice(0, 4);
        };
        setRandomQuestions(getRandomQuestions());

	}, []);

	return (
		<>
			<div className="adaptive-flex"  style={{marginTop: "30px"}}>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
					<a href="https://quepasa.ai" target="_blank">
		          		<img src="/logo.png" alt="Logo" style={{ width: '150px', height: 'auto' }} />
					</a>
		        </Box>

				<Typography variant="h5" component="h2">
					Anylogic
				</Typography>
			</div>

			{description.length > 0 && description.map((line, index) => (
				<ReactMarkdown
					key={index}
					components={{
						a: ({ node, ...props }) => (
						  <a {...props} target="_blank" rel="noopener noreferrer">
							{props.children}
						  </a>
						),
					}}
				>
					{line}
				</ReactMarkdown>
			))}

			<div style={{ marginBottom: '20px' }}>
				{quepasa && <LiveWiki quepasa={quepasa} />}
			</div>

			<div style={{ marginTop: '20px', marginBottom: '20px' }}>
				{quepasa && <QuestionForm domains={Object.keys([""])} questions={randomQuestions} url={false} onInteraction={handleInteractionWithQuestionForm} quepasa={quepasa} />}
			</div>
		</>
	);
}

export default Anylogic;
