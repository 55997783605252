import React, {useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

import HomePage from './components/HomePage';
import FinBench from './components/FinBench';
import RAGPapers from './components/RAGPapers';
import AICases from './components/AICases';
import MoonSite from './components/MoonSite';
import CourtCases from './components/CourtCases';
import Anylogic from './components/Anylogic';
import Footer from './components/Footer';
import LoginPage from './components/LoginPage';
import FinishSignUpWihEmailLink from './components/FinishSignUpWihEmailLink';
import ProtectedRoute from './components/ProtectedRoute';

import { DefaultApi, Configuration } from 'quepasa-ai';

const theme = createTheme();

function App(): JSX.Element {

	return (
		<ThemeProvider theme={theme}>
   			<CssBaseline />
   			<Router>
   				<Container maxWidth="lg" sx={{ mt: 4 }}>
   					<Routes>
						<Route path="/login" element={<LoginPage />} />
						<Route path="/finishSignUp" element={<FinishSignUpWihEmailLink />} />
						<Route path="/" element={( <ProtectedRoute><HomePage /></ProtectedRoute> )} />
   						<Route path="/fb" element={<FinBench />} />
						<Route path="/catalog/finance-bench" element={<FinBench />} />
						<Route path="/catalog/rag-papers" element={<RAGPapers />} />
						<Route path="/catalog/ai-cases" element={<AICases />} />
						<Route path="/catalog/moon-site" element={<MoonSite />} />
						<Route path="/catalog/court-cases" element={<CourtCases />} />
						<Route path="/catalog/anylogic" element={<Anylogic />} />
   					</Routes>
   				</Container>
				<Footer />
   			</Router>
	    </ThemeProvider>
	);
}

export default App;
