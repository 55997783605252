import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
	Card, CardContent,
	Typography,
	Alert,
	Button, IconButton,
	LinearProgress,
	Grid
} from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import QuepasaMarkdown from './QuepasaMarkdown'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { DefaultApi } from 'quepasa-ai';

// Interface for grouping entities by their type
interface EntityGroup {
  name: string;
  values: string[];
}

// Interface for individual entity structure
interface Entity {
  type: string;
  value: string;
}

// Main component props interface
interface LiveWikiProps {
	quepasa: DefaultApi;
}

// Response structure from the Wiki API
interface WikiResponse {
    data?: {
        entities: EntityGroup[];
    };
    entity?: Entity;
    answer?: string;
    links?: Record<string, { title?: string }>;
}

export default function LiveWiki({ quepasa }: LiveWikiProps): JSX.Element {
	// State for managing entity groups and their display
	const [entities, setEntities] = useState<EntityGroup[]>([]);
	const [entityTitle, setEntityTitle] = useState<Entity | null>(null);
	const [newEntityTitle, setNewEntityTitle] = useState<Entity | null>(null);
	const [links, setLinks] = useState<Record<string, {id?: string, text?: string, title?: string, url?: string}>>({});
	const [markdown, setMarkdown] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
	const [expandedItems, setExpandedItems] = React.useState<string[]>([]);

	// Fetches wiki page data either for all entities or a specific entity
	const getWikiPage = async (type?: string, value?: string) => {
		const allEntities = !(type && value);
		if (allEntities) {
			console.log("Get All Entities");
		} else {
			console.log("Get One Entity");
			setNewEntityTitle({type: type, value: value} as Entity);
		}

		setLoading(true);
		try {
			// Make API call based on whether we want all entities or a specific one
			const results: WikiResponse = allEntities
			    ? await quepasa.retrieveWiki({ show_text: true })
			    : await quepasa.retrieveWiki({ type: type.toLowerCase(), value: value, show_text: true });

			if (!results) {
				console.log('Failed to get LiveWiki Page.');
				setLoading(false);
			} else {
				// Update state with API response data
				if (results.data) setEntities(results.data.entities);
				if (results.entity) {
					setEntityTitle(results.entity as Entity);
					setNewEntityTitle(null);
				}
				if (results.links) setLinks(results.links);

				let answer = results.answer || 'No answer received.';
				setMarkdown(answer);
				setLoading(false);
			}

		} catch (err) {
			console.log('Failed to get LiveWiki Page.');
			console.log( err );
			setLoading(false);
		}
	};

	// Handles clicks on entity links in the markdown content
	const handleEntityClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault(); // Prevent default navigation behavior

		const url = new URL(e.currentTarget.href, window.location.origin);
		const entityType = url.searchParams.get("entity");
		const entityValue = url.searchParams.get("value");

		if (entityType && entityValue) {
			// Update browser history and fetch new entity data
			window.history.pushState({ entityType, entityValue }, '', `?entity=${entityType}&value=${entityValue}`);
			getWikiPage(entityType, entityValue);
		} else {
			console.warn("Invalid link structure");
		}
	};

	const updateHistory = (newQuestion: string, pushState = true) => {
        const params = new URLSearchParams();
        if (newQuestion) {
            params.set('question', newQuestion);
        }

        const state = {
            question: newQuestion,
        };

        const url = `${window.location.pathname}${params.toString() ? '?' + params.toString() : ''}`;

        if (pushState) {
            window.history.pushState(state, '', url);
        } else {
            window.history.replaceState(state, '', url);
        }
    };

	// Initialize component and setup browser navigation handling
	useEffect(() => {
		// Load page data from URL parameters
		const loadPageFromParams = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const entity = urlParams.get('entity');
            const value = urlParams.get('value');
            getWikiPage(entity || undefined, value || undefined);
        };

        // Handle browser back/forward navigation
        const handlePopState = (event: PopStateEvent) => {
            if (event.state) {
                const { entityType, entityValue } = event.state;
                getWikiPage(entityType, entityValue);
            } else {
                loadPageFromParams();
            }
        };

        // Initialize browser history state
        const urlParams = new URLSearchParams(window.location.search);
        const entity = urlParams.get('entity');
        const value = urlParams.get('value');
        if (entity && value) {
            window.history.replaceState(
                { entityType: entity, entityValue: value },
                '',
                window.location.search
            );
        }

        loadPageFromParams();
        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };

	}, []);

	// Generate safe IDs for tree items
	const generateSafeId = (input: string, index: number) =>
      encodeURIComponent(input) + `-${index}`;

	// Render the tree structure of entity groups and their values
	const renderTreeItems = (entities: EntityGroup[]) =>
	  entities.map((group, groupIndex) => (
	      <TreeItem
	          key={generateSafeId(group.name, groupIndex)}
	          itemId={generateSafeId(group.name, groupIndex)}
	          label={group.name}
	      >
	          {group.values.map((entity, entityIndex) => (
	              <TreeItem
	                  key={generateSafeId(group.name + entity, entityIndex)}
	                  itemId={generateSafeId(group.name + entity, entityIndex)}
	                  label={
								<a
									style={{textDecoration: 'none', color: 'black'}}
									href={`?entity=${group.name}&value=${entity}`}
									onClick={(e) => {
									  e.preventDefault();
									  window.history.pushState(
									      { entityType: group.name, entityValue: entity },
									      '',
									      `?entity=${group.name}&value=${entity}`
									  );
									  getWikiPage(group.name, entity);
									}}
								>
									{entity}
								</a>
							}
	              />
	          ))}
	      </TreeItem>
	  ));

 	return (
		<Card>
      		<CardContent>
				<Grid container spacing={2}>
		  			<Grid item xs={12} md={6}>
						<Typography variant="h6" component="h2" gutterBottom>
							{entityTitle && entityTitle.type && entityTitle.value ? (entityTitle.type + ": " + entityTitle.value) : "Live Wiki Page"}
							{newEntityTitle && newEntityTitle.type && newEntityTitle.value &&
								(
									<>
									  <ArrowForwardIcon
										style={{ fontSize: '20px', verticalAlign: 'middle', margin: '0 8px' }}
									  />
									  {`${newEntityTitle.type}: ${newEntityTitle.value}`}
									</>
								)
							}
						</Typography>

						{loading  && <LinearProgress  /> }

						{markdown &&
							<QuepasaMarkdown answer={markdown} links={links} onEntityClick={handleEntityClick}/>
						}
					</Grid>

					<Grid item xs={12} md={6}>
						<Typography variant="h6" component="h3" gutterBottom>
							Related Entities Tree
						</Typography>

						{loading  && <LinearProgress  /> }

						{entities.length > 0 &&
							<SimpleTreeView>{renderTreeItems(entities)}</SimpleTreeView>
						}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}
